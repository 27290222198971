.wrapper {
    width: 800px;
    margin: 0 auto;
}

body {
    margin: 60px 60px;
}

h1 {
    font-size: 3em;
}

h1, h2 {
    font-family:'Helvetica', 'Arial', 'Sans-Serif';
}

p {
    font-size: 1.5em;
    font-family:'Helvetica', 'Arial', 'Sans-Serif';
    line-height: 1.4em;
    color: #333;
}

footer {
    border-top: 1px solid #d5d5d5;
    font-size: .8em;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: #efefef;
    text-align: center;
    padding-top: 20px;
}

nav ul, footer ul {
    font-family:'Helvetica', 'Arial', 'Sans-Serif';
    padding: 0px;
    list-style: none;
    font-weight: bold;
}

footer ul {
    font-family:'Helvetica', 'Arial', 'Sans-Serif';
    padding: 20px;
    list-style: none;
    font-weight: bold;
}

nav ul li, footer ul li {
    display: inline;
    margin-right: 20px;
}

a {
    text-decoration: none;
    color: #999;
}
a:hover {
    text-decoration: underline;
}

.container {
    margin-bottom: 20%;
}

.timeline {
    font-family:'Helvetica', 'Arial', 'Sans-Serif';
}

.timeline img {
    float: left;
    width: 180px;
    padding-right: 25px;
    padding-top: 2px;
}

.timeline ul {
    overflow: auto;
}
